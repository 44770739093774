import React, { useState, useEffect, useContext, useRef } from "react";
import UserProfile from "../../components/profile/userProfile/UserProfile";
import AuthorPreference from "../../components/profile/authorPreference/AuthorPreference";
import GenrePreference from "../../components/profile/genrePreference/GenrePreference";
import ManageSubscription from "../../components/profile/manageSubscription/ManageSubscription";
import NotSubscribed from "../../components/profile/notSubscribed/NotSubscribed";
import { Context } from "../../context/Context";
import axios from "axios";
import "./profile.css";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const Profile = () => {
  const { user, token } = useContext(Context);

  const [activeSection, setActiveSection] = useState("user-info");
  const [selectedImage, setSelectedImage] = useState(null);
  const [bio, setBio] = useState("User's Bio");
  const [editingBio, setEditingBio] = useState(false);
  const [tempBio, setTempBio] = useState("");
  const [userData, setUserData] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const bioRef = useRef(null);

  const roles = {
    1: "Reader",
    2: "Writer",
    3: "Editor",
    4: "Admin",
    5: "SuperAdmin",
  };

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setUserData(res.data);
        setBio(res.data.bio);
      } catch (error) {
        console.error("Error fetching user data: " + error);
        toast.error("Error Fetching User!");
      }
    };

    if (user && token) {
      getUser();
    }
  }, [user, token]);

  useEffect(() => {
    const getSubscription = async () => {
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        if (res.data.subscription.plan !== "plan") {
          setSubscriptionStatus(true);
        } else {
          setSubscriptionStatus(false);
        }
      } catch (err) {
        console.error("Error getting Subscription! " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };

    if (user && token) {
      getSubscription();
    }
  }, [user, token]);

  useEffect(() => {
    const verifyPayment = async () => {
      const sessionId = searchParams.get("session_id");

      if (sessionId) {
        try {
          const res = await axios.get(
            `/stripe/verify-payment-session?session_id=${sessionId}`,
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (res.status === 200 && res.data.success) {
            toast.success("Payment verified successfully!");
            setSubscriptionStatus(true);
            navigate("/profile");
          } else {
            toast.error("Payment verification failed!");
          }
        } catch (error) {
          console.error("Error verifying payment: ", error);
          toast.error("Error verifying payment!");
        }
      }
    };

    verifyPayment();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toISOString().slice(0, 10);
  };

  const handleSaveUserData = async (formData) => {
    try {
      const res = await axios.put(`/users/${user._id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      toast.success("Updated!");
    } catch (error) {
      console.log("Error updating user data: ", error);
      toast.error("Error Saving Preferences!");
    }
  };

  const handleButtonClick = (section) => {
    setActiveSection(section);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      setSelectedImage(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleEditBio = () => {
    setTempBio(bio);
    setEditingBio(true);
  };

  useEffect(() => {
    if (editingBio) {
      bioRef.current.select();
    }
  }, [editingBio]);

  const handleSaveBio = () => {
    setBio(tempBio);
    setEditingBio(false);
    const updateBio = async () => {
      try {
        const res = await axios.put(
          `/users/${user._id}`,
          { bio: tempBio },
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        toast.success("Updated!");
      } catch (error) {
        console.log(error);
        toast.error("Error Updating Bio!");
      }
    };
    updateBio();
  };

  const handleChangeTempBio = (e) => {
    setTempBio(e.target.value);
  };

  useEffect(() => {
    const hamburgerIcon = document.querySelector(".menuIcon");
    const mobileMenu = document.querySelector(".mobile-menu");
    const closeIcon = document.querySelector(".closeIcon");

    const handleHamburgerClick = () => {
      mobileMenu.classList.toggle("hidden");
    };

    const handleCloseIconClick = () => {
      mobileMenu.classList.add("hidden");
    };

    if (hamburgerIcon) {
      hamburgerIcon.addEventListener("click", handleHamburgerClick);
    }

    if (closeIcon) {
      closeIcon.addEventListener("click", handleCloseIconClick);
    }

    return () => {
      if (hamburgerIcon) {
        hamburgerIcon.removeEventListener("click", handleHamburgerClick);
      }
      if (closeIcon) {
        closeIcon.removeEventListener("click", handleCloseIconClick);
      }
    };
  }, []);

  const handleSubscriptionCancelled = (newStatus) => {
    setSubscriptionStatus(newStatus);
    navigate("/profile");
  };

  return (
    <div className="container-profile">
      <div className="cards-profile">
        <div className="left-card-profile">
          <div className="user-info-profile">
            <h2>{userData?.user_name || "User's Full name"}</h2>
            <p>{userData?.username || "User's Username"}</p>
            <p>{userData?.pronouns || ""}</p>
            {userData?.role > 1 && <p>{roles[userData?.role]}</p>}
            <div className="profile-pic">
              <label className="-label" htmlFor="file">
                <span className="glyphicon glyphicon-camera"></span>
                <span>Change Image</span>
              </label>
              <input id="file" type="file" onChange={handleImageChange} />
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Profile"
                  id="output"
                  width="200"
                />
              )}
            </div>
            <div className="input-container">
              <div style={{ position: "relative" }}>
                <textarea
                  id="editableInput"
                  ref={bioRef}
                  value={editingBio ? tempBio : bio}
                  placeholder="Tell us a little about yourself..."
                  onChange={handleChangeTempBio}
                  disabled={!editingBio}
                ></textarea>
                {editingBio ? (
                  <span
                    className="save-icon"
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      right: "8px",
                      cursor: "pointer",
                    }}
                    onClick={handleSaveBio}
                  >
                    &#10003;
                  </span>
                ) : (
                  <span className="pencil-icon" onClick={handleEditBio}>
                    &#9998;
                  </span>
                )}
              </div>
            </div>
            <p style={{ marginTop: "10px" }}>
              Member since:<b> {formatDate(user.createdAt)}</b>
            </p>
          </div>
        </div>
        <div className="right-card-profile">
          <div className="black-section">
            <h2
              className="edit-profile"
              style={{ fontSize: "32px", fontWeight: "700", marginTop: "15px" }}
            >
              Edit Profile
            </h2>
            <ul className="profile-sections">
              <li>
                <button
                  onClick={() => handleButtonClick("user-info")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight: activeSection === "user-info" ? "" : "normal",
                  }}
                >
                  User Info
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("author-preference")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "author-preference" ? "" : "normal",
                  }}
                >
                  Writer Preferences
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("genre-preference")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "genre-preference" ? "" : "normal",
                  }}
                >
                  Genre Preferences
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleButtonClick("manage-subscription")}
                  className="profile-button"
                  style={{
                    fontSize: "20px",
                    fontWeight:
                      activeSection === "manage-subscription" ? "" : "normal",
                  }}
                >
                  Manage Subscription
                </button>
              </li>
            </ul>
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "user-info" ? "block" : "none",
            }}
          >
            <UserProfile onSave={handleSaveUserData} />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "author-preference" ? "block" : "none",
            }}
          >
            <AuthorPreference />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display: activeSection === "genre-preference" ? "block" : "none",
            }}
          >
            <GenrePreference />
          </div>
          <div
            className="form-section-profile2"
            style={{
              display:
                activeSection === "manage-subscription" ? "block" : "none",
            }}
          >
            {subscriptionStatus ? (
              <ManageSubscription
                onSubscriptionCancelled={handleSubscriptionCancelled}
              />
            ) : (
              <NotSubscribed />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;

// import React, { useState, useEffect, useContext, useRef } from "react";
// import UserProfile from "../../components/profile/userProfile/UserProfile";
// import AuthorPreference from "../../components/profile/authorPreference/AuthorPreference";
// import GenrePreference from "../../components/profile/genrePreference/GenrePreference";
// import ManageSubscription from "../../components/profile/manageSubscription/ManageSubscription";
// import NotSubscribed from "../../components/profile/notSubscribed/NotSubscribed";
// import { Context } from "../../context/Context";
// import axios from "axios";
// import "./profile.css";
// import { toast } from "react-toastify";

// const Profile = () => {
//   // const PF = process.env.REACT_APP_BACKEND_URL + "/api/images";

//   const { user } = useContext(Context);

//   const [activeSection, setActiveSection] = useState("user-info");
//   const [selectedImage, setSelectedImage] = useState(null);
//   const [bio, setBio] = useState("User's Bio");
//   const [editingBio, setEditingBio] = useState(false);
//   const [tempBio, setTempBio] = useState("");
//   const [userData, setUserData] = useState(null);
//   const [subscriptionStatus, setSubscriptionStatus] = useState(false);
//   const bioRef = useRef(null);

//   useEffect(() => {
//     const getUser = async () => {
//       try {
//         const res = await axios.get(`/users/${user.userId}`);
//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         }
//         setUserData(res.data);
//         setBio(res.data.bio);
//       } catch (error) {
//         console.error("Error fetching user data: " + error);
//         toast.error("Error Fetching User!");
//       }
//     };
//     const getSubscription = async () => {
//       try {
//         const res = await axios.get(`users/${user.userId}`);
//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         }
//         //If user is a subscriber
//         if (res.data.subscription.plan !== "plan") {
//           setSubscriptionStatus(true);
//         }
//         //If user has default planType 'plan', user is not a subscriber
//         else {
//           setSubscriptionStatus(false);
//         }
//       } catch (err) {
//         console.error("Error getting Subscription! " + err);
//         toast.error("Error Fetching Subscription Details!");
//       }
//     };
//     getSubscription();
//     if (user) {
//       getUser();
//     }
//   }, [user, userData]);

//   const formatDate = (dateString) => {
//     // const options = { year: "numeric", month: "long", day: "numeric" };
//     return new Date(dateString).toISOString().slice(0, 10);
//   };

//   const handleSaveUserData = async (formData) => {
//     try {
//       const res = await axios.put(`/users/${user._id}`, formData);
//       if (res.status !== 200) {
//         toast.info(res.data.msg);
//       }
//       console.log("Updated user data successfully.", res.data);
//       toast.success("Updated!");
//     } catch (error) {
//       console.log("Error updating user data: ", error);
//       toast.error("Error Saving Preferences!");
//     }
//   };

//   const handleButtonClick = (section) => {
//     setActiveSection(section);
//   };

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();

//     reader.onload = (e) => {
//       setSelectedImage(e.target.result);
//     };

//     reader.readAsDataURL(file);
//     //handleSaveImage();
//   };

//   // const handleSaveImage = async () => {
//   //   const res = await axios.put(`/users/${user._id}` )
//   // }

//   const handleEditBio = () => {
//     setTempBio(bio);
//     setEditingBio(true);
//   };

//   useEffect(() => {
//     bioRef.current.select();
//   }, [editingBio]);

//   const handleSaveBio = () => {
//     setBio(tempBio);
//     setEditingBio(false);
//     const updateBio = async () => {
//       try {
//         const res = await axios.put(`/users/${user._id}`, { bio: tempBio });
//         if (res.status !== 200) {
//           toast.info(res.data.msg);
//         }
//         console.log("Updated bio successfully.", res.data);
//         toast.success("Updated!");
//       } catch (error) {
//         console.log(error);
//         toast.error("Error Updating Bio!");
//       }
//     };
//     updateBio();
//   };

//   // const handleCancelEditBio = () => {
//   //   setEditingBio(false);
//   // };

//   const handleChangeTempBio = (e) => {
//     setTempBio(e.target.value);
//   };

//   useEffect(() => {
//     const hamburgerIcon = document.querySelector(".menuIcon");
//     const mobileMenu = document.querySelector(".mobile-menu");
//     const closeIcon = document.querySelector(".closeIcon");

//     const handleHamburgerClick = () => {
//       mobileMenu.classList.toggle("hidden");
//     };

//     const handleCloseIconClick = () => {
//       mobileMenu.classList.add("hidden");
//     };

//     if (hamburgerIcon) {
//       hamburgerIcon.addEventListener("click", handleHamburgerClick);
//     }

//     if (closeIcon) {
//       closeIcon.addEventListener("click", handleCloseIconClick);
//     }

//     // Cleanup
//     return () => {
//       if (hamburgerIcon) {
//         hamburgerIcon.removeEventListener("click", handleHamburgerClick);
//       }
//       if (closeIcon) {
//         closeIcon.removeEventListener("click", handleCloseIconClick);
//       }
//     };
//   }, []);
//   return (
//     <div className="container-profile">
//       <div className="cards-profile">
//         <div className="left-card-profile">
//           <div className="user-info-profile">
//             <h2>{userData?.user_name || "User's Full name"}</h2>
//             <p>{userData?.username || "User's Username"}</p>
//             <p>{userData?.pronouns || ""}</p>
//             <div className="profile-pic">
//               <label className="-label" htmlFor="file">
//                 <span className="glyphicon glyphicon-camera"></span>
//                 <span>Change Image</span>
//               </label>
//               <input id="file" type="file" onChange={handleImageChange} />
//               {selectedImage && (
//                 <img
//                   src={selectedImage}
//                   alt="Profile"
//                   id="output"
//                   width="200"
//                 />
//               )}
//             </div>
//             <div className="input-container">
//               <div style={{ position: "relative" }}>
//                 <textarea
//                   id="editableInput"
//                   ref={bioRef}
//                   value={editingBio ? tempBio : bio}
//                   placeholder="Tell us a little about yourself..."
//                   onChange={handleChangeTempBio}
//                   disabled={!editingBio}
//                 ></textarea>
//                 {editingBio ? (
//                   <span
//                     className="save-icon"
//                     style={{
//                       position: "absolute",
//                       bottom: "8px",
//                       right: "8px",
//                       cursor: "pointer",
//                     }}
//                     onClick={handleSaveBio}
//                   >
//                     &#10003;
//                   </span>
//                 ) : (
//                   <span className="pencil-icon" onClick={handleEditBio}>
//                     &#9998;
//                   </span>
//                 )}
//               </div>
//             </div>
//             <p style={{ marginTop: "10px" }}>
//               Member since:<b> {formatDate(user.createdAt)}</b>
//             </p>
//           </div>
//         </div>
//         <div className="right-card-profile">
//           <div className="black-section">
//             <h2
//               className="edit-profile"
//               style={{ fontSize: "32px", fontWeight: "700", marginTop: "15px" }}
//             >
//               Edit Profile
//             </h2>
//             <ul className="profile-sections">
//               <li>
//                 <button
//                   onClick={() => handleButtonClick("user-info")}
//                   className="profile-button"
//                   style={{
//                     fontSize: "20px",
//                     fontWeight: activeSection === "user-info" ? "" : "normal",
//                   }}
//                 >
//                   User Info
//                 </button>
//               </li>
//               <li>
//                 <button
//                   onClick={() => handleButtonClick("author-preference")}
//                   className="profile-button"
//                   style={{
//                     fontSize: "20px",
//                     fontWeight:
//                       activeSection === "author-preference" ? "" : "normal",
//                   }}
//                 >
//                   Writer Preferences
//                 </button>
//               </li>
//               <li>
//                 <button
//                   onClick={() => handleButtonClick("genre-preference")}
//                   className="profile-button"
//                   style={{
//                     fontSize: "20px",
//                     fontWeight:
//                       activeSection === "genre-preference" ? "" : "normal",
//                   }}
//                 >
//                   Genre Preferences
//                 </button>
//               </li>
//               <li>
//                 <button
//                   onClick={() => handleButtonClick("manage-subscription")}
//                   className="profile-button"
//                   style={{
//                     fontSize: "20px",
//                     fontWeight:
//                       activeSection === "manage-subscription" ? "" : "normal",
//                   }}
//                 >
//                   Manage Subscription
//                 </button>
//               </li>
//             </ul>
//           </div>
//           <div
//             className="form-section-profile2"
//             style={{
//               display: activeSection === "user-info" ? "block" : "none",
//             }}
//           >
//             <UserProfile onSave={handleSaveUserData} />
//           </div>
//           <div
//             className="form-section-profile2"
//             style={{
//               display: activeSection === "author-preference" ? "block" : "none",
//             }}
//           >
//             <AuthorPreference />
//           </div>
//           <div
//             className="form-section-profile2"
//             style={{
//               display: activeSection === "genre-preference" ? "block" : "none",
//             }}
//           >
//             <GenrePreference />
//           </div>
//           <div
//             className="form-section-profile2"
//             style={{
//               display:
//                 activeSection === "manage-subscription" ? "block" : "none",
//             }}
//           >
//             {subscriptionStatus ? <ManageSubscription /> : <NotSubscribed />}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Profile;
