import "./categories.css";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import { decodeHtml } from "../../utils/utils";
import { toast } from "react-toastify";
const PF = process.env.REACT_APP_AWS_S3_IMAGE_URL;

// Sample data for cards (replace with your actual data)
export default function Categories() {
  const [cats, setCats] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const postsPerPage = 6;

  useEffect(() => {
    const getCats = async () => {
      try {
        const res = await axios.get("/categories");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setCats(res.data);
      } catch (err) {
        console.error("Error fetching genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };

    getCats();
  }, []);

  // const indexOfLastPost = currentPage * postsPerPage;
  // const indexOfFirstPost = indexOfLastPost - postsPerPage;
  // const currentPosts = cats.slice(indexOfFirstPost, indexOfLastPost);

  // // Change page
  // const nextPage = () => setCurrentPage(currentPage + 1);
  // const prevPage = () => setCurrentPage(currentPage - 1);

  return (
    <>
      <div className="header-container">
        <h1 className="header-title-categories">Categories</h1>
        {/* <button className="filter-btn">
          Filter{" "}
          <FontAwesomeIcon icon={faFilter} style={{ marginLeft: "20px" }} />
        </button> */}
      </div>

      <div className="card-container">
        {cats.map((cat, index) => (
          <div className="card-categories" key={index}>
            <div className="image-container">
              <Link to={`/category/${cat._id}`}>
                <img
                  src={cat.cat_image.startsWith('https') ? cat.cat_image : `${process.env.process.env.REACT_APP_AWS_S3_IMAGE_URL}${cat.cat_image}`}
                  alt="Card Image"
                />
                <div className="caption-categories">
                  {decodeHtml(cat.cat_name)}
                </div>
              </Link>
            </div>
            <div className="card-content">
              <h2>{cat.cat_slug}</h2>
              <p>{cat.cat_desc}</p>
            </div>
          </div>
        ))}
      </div>
      {/* <div className="pagination">
        <button
          style={{ width: "50px", height: "30px", backgroundColor: "#B1E5F2" }}
          className="pagination-left-btn"
          onClick={prevPage}
          disabled={currentPage === 1}
        >
          Prev
        </button>
        <button
          style={{ width: "50px", height: "30px", backgroundColor: "#B1E5F2" }}
          className="pagination-right-btn"
          onClick={nextPage}
          disabled={currentPosts.length < postsPerPage}
        >
          Next
        </button>
      </div> */}
    </>
  );
}
