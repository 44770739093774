import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import "./register.css";
import { Context } from "../../context/Context";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useContext, useState } from "react";
import React from "react";
import { toast } from "react-toastify";

export default function Register() {
  const [user_name, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();

  const generateTempUsername = (name) => {
    const formattedName = name.toLowerCase().replace(/\s/g, "");
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;
    const tempUsername = `${formattedName}${randomNumber}`;

    return tempUsername;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });
    // alert(interests);
    try {
      const res = await axios.post("/auth/register", {
        user_name,
        username: generateTempUsername(user_name),
        email,
        password,
      });
      if (res.status !== 200) {
          toast.info(res.data.msg);
      }
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      res.data &&
        window.location.replace(`/username?user_id=${res.data.user.userId}`);
    } catch (err) {
      toast.error("Internal server error");
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  const googleLogin = async (credentialResponse) => {
    dispatch({ type: "LOGIN_START" });

    try {
      const { credential } = credentialResponse;
      const decoded = jwtDecode(credential);

      // Extract user info from the decoded JWT token
      const { email, name, picture } = decoded;

      const res = await axios.post("/auth/google", {
        user_name: name,
        username: generateTempUsername(name),
        email,
        profilePic: picture,
        password: "Admin@123",
      });
      if (res.status !== 200) {
          toast.info(res.data.msg);
        }
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE" });
    }
  };

  return (
    <>
      <div className="page-container">
        <div className="left-container">
          <Link to="/">
            <img
              src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/logo1.png"}
              alt="For Love of Writers Logo"
              className="logo-register"
            />
          </Link>
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/bottom_image.jpg"}
            alt="Your Image"
            className="picture"
          />
        </div>
        <div className="right-container">
        {/* <Header /> */}
          <form onSubmit={handleSubmit} className="form-register">
            <h1
            >
              Sign Me Up!
            </h1>
            <div className="input-container-register">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Your Name"
                required
                onChange={(e) => setUsername(e.target.value)}
              />

              <label htmlFor="email address">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Email Address"
                required
                onChange={(e) => setEmail(e.target.value)}
              />

              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />

                <div className="remember-me" style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    className="input-login"
                    style={{ width: "19px", height: "19px", backgroundColor: "#C9CDD3" }}
                    required
                  />
                  <p
                    style={{
                      paddingTop: "8px",
                      
                      fontWeight: 400,
                    }}
                  >
                    I agree to receive regular e-newsletters and e-mail marketing from For Love of Writers.*
                  </p>
                </div>

              <div style={{ justifyContent: "center", paddingTop: "20px" }}>
                <button type="submit" className="RegisterBtn">
                  Create Account
                </button>
              </div>
            </div>
            <p>
              Already have an account?{" "}
              <Link style={{ textDecoration: "none" }} to="/login">
                {" "}
                Log in
              </Link>
            </p>
            {/* {error && (
              <span style={{ color: "red", marginTop: "10px" }}>
                Something went wrong!
              </span>
            )} */}

            <div className="separator-register">
              <hr />
              <span>OR</span>
              <hr />
            </div>

            <div className="button-row">
              <div style={{ justifyContent: "center", paddingTop: "20px" }}>
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                  <GoogleLogin
                    shape="rectangular"
                    theme="filled_blue"
                    onSuccess={googleLogin}
                  />
                </GoogleOAuthProvider>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
