// pages/Write.jsx
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { Context } from "../../context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import "./write.css";
import { createSlug, decodeHtml } from "../../utils/utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { resizeImage } from "browser-image-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faPenToSquare,
  faTrashCan,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { jwtDecode } from "jwt-decode";

export default function Write() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 16 / 9 });
  const [caption, setCaption] = useState("");
  const [url, setUrl] = useState("");
  const [seoTitle, setSeoTitle] = useState("");
  const [postSlug, setPostSlug] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [focusKeyphrase, setFocusKeyphrase] = useState("");
  const { user, token } = useContext(Context);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [articles, setArticles] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const [showTooltip, setShowTooltip] = useState(false);
  const [images, setImages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [mainAuthor, setMainAuthor] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);

  const location = useLocation();
  //const draftId = new URLSearchParams(location.search).get("draft");
  const draftSlug = new URLSearchParams(location.search).get("draft");

  const loadArticles = async () => {
    try {
      const articlesRes = await axios.get(`/posts`);
      setArticles(
        articlesRes.data.filter((article) => article.status !== "draft")
      );
      setDrafts(
        articlesRes.data.filter((article) => article.status === "draft")
      );
    } catch (err) {
      console.error("Error fetching articles", err);
    }
  };

  const handleEditDraft = (draftSlug) => {
    //setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
    navigate(`/write?draft=${draftSlug}`);
  };

  const handleEditArticle = (articleSlug) => {
    //setIsSidebarOpen(!isSidebarOpen);
    toggleSidebar();
    navigate(`/write?draft=${articleSlug}`);
  };

  const handleDeleteArticle = async (articleId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BACKEND_LOCAL_URL}posts/${articleId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setArticles(articles.filter((article) => article._id !== articleId));
      toast.success("Article deleted successfully!");
    } catch (err) {
      console.error("Error deleting draft", err);
      toast.error("Error deleting draft");
    }
  };

  const handleDeleteDraft = async (draftId) => {
    try {
      
      await axios.delete(`posts/${draftId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDrafts(drafts.filter((draft) => draft._id !== draftId));
      toast.success("Draft deleted successfully!");
    } catch (err) {
      console.error("Error deleting draft", err);
      toast.error("Error deleting draft");
    }
  };

  const handleSwitchToPublish = async (draftId) => {
    try {
      const newArticle = {
        status: "published",
      };

      await axios.put(`/posts/${draftId}`, newArticle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDrafts(drafts.filter((draft) => draft._id !== draftId));
      toast.success("Successfully switched to article!");
      loadArticles();
    } catch (err) {}
  };

  const handleSwitchToDraft = async (articleId) => {
    try {
      const newArticle = {
        status: "draft",
      };

      await axios.put(`/posts/${articleId}`, newArticle, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setArticles(articles.filter((art) => art._id !== articleId));
      toast.success("Successfully switched to draft!");
      loadArticles();
    } catch (err) {}
  };

  const getMainAuthor = async () => {
    const res = await axios.get(`/users`);
    const authors = res.data.filter((user) => user.isAuthor);
    const author = authors.find((author) => author.user_name === mainAuthor);
    let authorId;
    if (author) {
      authorId = author.userId;
    }
    return authorId;
  };

  useEffect(() => {
    console.log(process.env.REACT_APP_BACKEND_LOCAL_URL)
    if (!user || !token) {
      navigate(-1);
      return;
    }
    const userRole = jwtDecode(token).role;
    if (userRole > 2) {
      setIsAuthorized(true);
    } else {
      navigate(-1);
    }
  }, [token, user, navigate]);

  useEffect(() => {
    loadArticles();
  }, []);

  useEffect(() => {
    const loadCategoriesAndTags = async () => {
      try {
        const authorsRes = await axios.get("/users");
        const authors = authorsRes.data.filter((user) => user.isAuthor);
        setAuthors(
          authors.map((user) => ({
            label: decodeHtml(user.user_name),
            value: decodeHtml(user.user_slug),
          }))
        );

        const categoriesRes = await axios.get(`/categories`);
        setCategories(
          categoriesRes.data.map((cat) => ({
            label: decodeHtml(cat.cat_name),
            value: decodeHtml(cat.cat_slug),
          }))
        );

        const tagsRes = await axios.get(`/tags`);
        setTags(
          tagsRes.data.map((tag) => ({
            label: decodeHtml(tag.tag_name),
            value: decodeHtml(tag.tag_slug),
          }))
        );
      } catch (err) {
        console.error("Error fetching categories or tags", err);
      }
    };

    const loadDraft = async (slug) => {
      try {
        const draftRes = await axios.get(`/posts/${slug}`);
        const draft = draftRes.data;
        console.log(draft);
        setTitle(draft.title);
        setContent(draft.desc);
        setPostSlug(draft.post_slug);
        setSeoTitle(draft.seoTitle || "");
        setMetaDescription(draft.metaDescription || "");
        setFocusKeyphrase(draft.focusKeyphrase || "");
        setUrl(draft.url || "");
        setCaption(draft.photoCaption || "");
        setSelectedCategories(draft.categories);
        setSelectedAuthors(draft.authors);
        setSelectedTags(draft.tags);
        setCurrentArticleId(draft._id);
        setIsUpdating(true);
        const author = await axios.get(`/users/${draft.authorId}`);
        setMainAuthor(author.data.user_name);
        // Set the image
        if (draft.photo) {
          // If the image is a URL or a filename, you can set it accordingly
          // Assuming draft.photo is the URL or relative path to the image
          const imageUrl = `${process.env.REACT_APP_AWS_S3_IMAGE_URL}${draft.photo}`;
          setFile(null);
          setImage(imageUrl);
        }
      } catch (err) {
        console.error("Error fetching draft", err);
      }
    };

    loadCategoriesAndTags();
    loadArticles();

    if (draftSlug) {
      loadDraft(draftSlug);
    }
  }, [draftSlug]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Validate file size
    if (file.size > 500 * 1024) {
      toast.error("File size exceeds 500 KB.");
      return;
    }

    // Validate file format
    const validFormats = ["image/jpeg", "image/png", "image/webp"];
    if (!validFormats.includes(file.type)) {
      toast.error(
        "Unsupported file format. Please upload a JPEG, PNG, or WebP image."
      );
      return;
    }

    // Read file and set image state for cropping
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
    setFile(file);
  };

  const handleImageLoaded = (image) => {
    this.imageRef = image;
  };

  const handleCropComplete = async (crop) => {
    if (image && crop.width && crop.height) {
      // Get cropped image
      const croppedImageUrl = await getCroppedImg(image, crop);
      // Resize image
      const resizedImage = await resizeImage(croppedImageUrl, {
        maxWidth: 1200,
        maxHeight: 630,
        compressFormat: "JPEG",
        quality: 0.8,
      });
      // Set the resized image
      setFile(resizedImage);
    }
  };

  const getCroppedImg = (image, crop) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const scaleX = image.width / image.naturalWidth;
      const scaleY = image.height / image.naturalHeight;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      canvas.toBlob((blob) => {
        resolve(URL.createObjectURL(blob));
      }, "image/jpeg");
    });
  };

  const handleSaveAsDraft = async () => {
    const authorId = await getMainAuthor();
    const newArticle = {
      authorId,
      authors: selectedAuthors,
      title,
      shortDesc: content.slice(0, 150),
      desc: content,
      categories: selectedCategories,
      tags: selectedTags,
      seoTitle,
      metaDescription,
      focusKeyphrase,
      url,
      photoCaption: caption,
      status: "draft",
      published: false,
      post_slug: postSlug,
    };

    // Handle file upload if there is a file
    if (file && file instanceof File) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file
        const uploadResponse = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        newArticle.photo = uploadResponse.data.file; // Add filename to the article object
      } catch (err) {
        console.error("Error uploading image", err);
        toast.error("Error uploading image!");
        return; // Exit the function if file upload fails
      }
    }

    try {
      if (isUpdating) {
        await axios.put(`/posts/${currentArticleId}`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Draft updated successfully!");
      } else {
        await axios.post(`/posts`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Draft saved successfully!");
      }

      loadArticles();
    } catch (err) {
      console.error("Error saving draft", err);
      toast.error("Error saving draft");
    }
  };

  const handlePublish = async () => {
    const authorId = await getMainAuthor();
    const newArticle = {
      authorId,
      authors: selectedAuthors,
      title,
      shortDesc: content.slice(0, 150),
      desc: content,
      categories: selectedCategories,
      tags: selectedTags,
      seoTitle,
      metaDescription,
      focusKeyphrase,
      url,
      photoCaption: caption,
      status: "published",
      published: true,
      post_slug: postSlug,
    };

    // Handle file upload if there is a file
    if (file && file instanceof File) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        // Upload the file
        const uploadResponse = await axios.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        newArticle.photo = uploadResponse.data.file; // Add filename to the article object
      } catch (err) {
        console.error("Error uploading image", err);
        toast.error("Error uploading image!");
        return; // Exit the function if file upload fails
      }
    }

    try {
      if (isUpdating) {
        await axios.put(`/posts/${currentArticleId}`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Article updated successfully!");
      } else {
        await axios.post(`/posts`, newArticle, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Article published successfully!");
      }

      loadArticles();
    } catch (err) {
      console.error("Error publishing article", err);
      toast.error("Error publishing article");
    }
  };

  const handleCancelDraft = () => {
    setTitle("");
    setContent("");
    setPostSlug("");
    setSeoTitle("");
    setMetaDescription("");
    setFocusKeyphrase("");
    setCaption("");
    setUrl("");
    setSelectedCategories([]);
    setSelectedAuthors([]);
    setSelectedTags([]);
    setCurrentArticleId("");
    setFile(null);
    setImage(null);
    setCrop({ unit: "%", width: 30, aspect: 16 / 9 });
    setIsUpdating(false);
    navigate("/write");
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    document.body.style.overflow = isSidebarOpen ? "auto" : "hidden"; // Toggle scrolling
  };

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#C9CDD3", // desired background color
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2, // dropdown appears above other content
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF", // Background color of selected options
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000000", // Text color of selected options
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#272635", // Color of the dropdown arrow
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "#272635", // Color of the separator between the arrow and the input
    }),
  };

  const handleImageUpload = (files) => {
    const newImages = Array.from(files);
    setSelectedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace" && selectedImages.length > 0) {
      setSelectedImages(selectedImages.slice(0, -1));
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const addTagToDatabase = async (newTag) => {
    try {
      const response = await axios.post(
        "/tags",
        { tag_name: newTag, tag_slug: createSlug(newTag) },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.status === 201) {
        const createdTag = response.data;
        setTags((prevTags) => [...prevTags, createdTag]);
      }
    } catch (error) {
      console.error("Error adding tag to the database:", error);
    }
  };

  const handleTagChange = (opts) => {
    console.log("inside");
    const newTags = opts.filter(
      (opt) => !tags.some((tag) => tag.value === opt.value)
    );
    newTags.forEach((newTag) => addTagToDatabase(newTag.label));

    setSelectedTags(
      opts.map((opt) => ({
        label: opt.label,
        value: decodeHtml(opt.value),
      }))
    );
  };

  if (!user || !token || !isAuthorized) {
    return <div>Not Authorized!</div>;
  }

  return (
    <div className="Write">
      <div
        className={`dimmed-background ${isSidebarOpen ? "visible" : ""}`}
        onClick={toggleSidebar}
      ></div>

      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <button className="sidebar-toggle" onClick={toggleSidebar}>
          {isSidebarOpen ? "→" : "←"}
        </button>
        <div className="sidebar-content">
          <div className="DraftManagement">
            <h2 style={{ textAlign: "center" }}>Drafts</h2>
            <ul>
              {drafts.map((draft) => (
                <li key={draft._id}>
                  <span>{draft.title}</span>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditDraft(draft.post_slug)}
                  />
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteDraft(draft._id)}
                  />
                  <FontAwesomeIcon
                    icon={faLock}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSwitchToPublish(draft._id)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="ArticleList">
            <h2 style={{ textAlign: "center" }}>Articles</h2>
            <ul style={{ textAlign: "end" }}>
              {articles.map((art) => (
                <li key={art._id}>
                  <Link to={`/${art.post_slug}`}>{art.title}</Link>
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditArticle(art.post_slug)}
                  />
                  <FontAwesomeIcon
                    icon={faTrashCan}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteArticle(art._id)}
                  />
                  <FontAwesomeIcon
                    icon={faUnlock}
                    style={{
                      marginRight: "15px",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSwitchToDraft(art._id)}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="write">
        <div className="write-title">
          <p>Writing Page!</p>
        </div>
        {file ? (
          <>
            <img
              className="writeImg"
              src={URL.createObjectURL(file)}
              alt="Selected File"
            />
            <div className="writeFormGroup">
              <label>Image Caption: </label>
              <input
                type="text"
                placeholder="Image Caption"
                className="writeInput"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          </>
        ) : image ? (
          <>
            <img className="writeImg" src={image} alt="Draft" />
            <div className="writeFormGroup">
              <label>Image Caption: </label>
              <input
                type="text"
                placeholder="Image Caption"
                className="writeInput"
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        <br />
        <form className="writeForm" onSubmit={(e) => e.preventDefault()}>
          <div className="writeFormGroup">
            <label>Main Author: </label>
            <input
              type="text"
              placeholder="Main Author"
              className="writeInput"
              autoFocus={true}
              value={mainAuthor}
              onChange={(e) => setMainAuthor(e.target.value)}
            />
          </div>
          <div className="optionClass">
            <div className="categories">
              <label>Authors: </label>
              <div className="select">
                <Select
                  options={authors}
                  isMulti
                  value={selectedAuthors}
                  onChange={(opts) =>
                    setSelectedAuthors(
                      opts.map((opt) => ({
                        label: opt.label,
                        value: decodeHtml(opt.value),
                      }))
                    )
                  }
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Title: </label>
            <input
              type="text"
              placeholder="Title"
              className="writeInput"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="writeFormGroup">
            <label>SEO Title: </label>
            <input
              type="text"
              placeholder="SEO Title"
              className="writeInput"
              value={seoTitle}
              onChange={(e) => setSeoTitle(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && (
                <div className="tooltip">Start with keyphrase</div>
              )}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Meta Description: </label>
            <input
              type="text"
              placeholder="Meta Description"
              className="writeInput"
              value={metaDescription}
              onChange={(e) => setMetaDescription(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">120-156 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Focus Keyphrase: </label>
            <input
              type="text"
              placeholder="Focus Keyphrase"
              className="writeInput"
              value={focusKeyphrase}
              onChange={(e) => setFocusKeyphrase(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">3-5 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Slug: </label>
            <input
              type="text"
              placeholder="Slug"
              className="writeInput"
              value={postSlug}
              onChange={(e) => setPostSlug(e.target.value)}
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">Include Keyphrase</div>}
            </div>
          </div>

          <div className="optionClass">
            <div className="categories">
              <label>Categories: </label>
              <div className="select">
                <Select
                  options={categories}
                  isMulti
                  value={selectedCategories}
                  onChange={(opts) =>
                    setSelectedCategories(
                      opts.map((opt) => ({
                        label: opt.label,
                        value: decodeHtml(opt.value),
                      }))
                    )
                  }
                  styles={customStyles}
                />
              </div>
            </div>
            <div className="tags">
              <label>Tags:</label>
              <div className="select">
                <CreatableSelect
                  isMulti
                  options={tags}
                  value={selectedTags}
                  onChange={handleTagChange}
                  styles={customStyles}
                  placeholder="Select or create tags"
                />
                {/* <Select
                  options={tags}
                  isMulti
                  value={selectedTags}
                  onChange={(opts) =>
                    setSelectedTags(
                      opts.map((opt) => ({
                        label: opt.label,
                        value: decodeHtml(opt.value),
                      }))
                    )
                  }
                  styles={customStyles}
                /> */}
              </div>
            </div>
          </div>

          <div className="featured-images">
            <label>Featured Images:</label>
            <div
              className="featured-images-container"
              onKeyDown={handleKeyDown}
              tabIndex={0}
            >
              <div className="image-preview">
                {selectedImages.map((image, index) => (
                  <div key={index} className="image-wrapper">
                    <img
                      src={URL.createObjectURL(image)}
                      alt={`preview-${index}`}
                    />
                  </div>
                ))}
              </div>
              <div className="inside-continer">
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => handleImageUpload(e.target.files)}
                  className="image-featured-images"
                />
                <span className="upload-icon">+</span>
              </div>
            </div>
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && (
                <div className="tooltip">Visible on the homepage</div>
              )}
            </div>
          </div>
          <div className="writeFormGroup">
            <label>Content:</label>
            <ReactQuill
              style={{ border: "none" }}
              value={content}
              onChange={setContent}
              modules={{
                toolbar: [
                  [
                    { header: "1" },
                    { header: "2" },
                    {
                      font: [],
                    },
                  ],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image", "video"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "blockquote",
                "list",
                "bullet",
                "indent",
                "link",
                "image",
                "video",
              ]}
              className="writeText"
            />
            <div className="tooltip-container">
              <i className="info-icon" onClick={toggleTooltip}>
                ℹ️
              </i>
              {showTooltip && <div className="tooltip">Minimum 500 Words</div>}
            </div>
          </div>
          <div className="writeFormGroup-buttons">
            <FontAwesomeIcon
              icon={faTrashCan}
              style={{
                marginRight: "15px",
                fontSize: "20px",
                cursor: "pointer",
              }}
              onClick={handleCancelDraft}
            />
            {/* {isUpdating ? (
              <button className="writeSubmit" onClick={handleSaveAsDraft}>
                {"Update"}
              </button>
            ) : ( */}
            <button className="writeSubmit" onClick={handlePublish}>
              {"Publish"}
            </button>
            {/* )} */}

            {/* {isUpdating ? (
              <button className="writeSubmitDraft" onClick={handleCancelDraft}>
                Cancel
              </button>
            ) : ( */}
            <button className="writeSubmitDraft" onClick={handleSaveAsDraft}>
              Save as Draft
            </button>
            {/* )} */}
          </div>
        </form>
        {/* {image && (
          <ReactCrop
            src={image}
            crop={crop}
            ruleOfThirds
            onImageLoaded={handleImageLoaded}
            onComplete={handleCropComplete}
            onChange={(newCrop) => setCrop(newCrop)}
          />
        )} */}
      </div>
      {/* <agement />
      <ArticleList articles={articles} /> */}
    </div>
  );
}
