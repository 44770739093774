import React from "react";
import "./faq.css";

export default function Faq() {
  return (
    <>
      <div className="faq-page">
        <h1>Frequently Asked Questions</h1>
        <img
          className="faq-responsive-image"
          src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/faq_temp.jpg"}
          alt="FAQ"
        />
      </div>
    </>
  );
}
