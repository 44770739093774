import React, { useContext, useEffect, forwardRef } from "react";
import "./subscribeOrSharePopup.css";
import { Context } from "../../context/Context";

const SubscribeOrSharePopup = forwardRef(({ onClose }, ref) => {
  const { userViews } = useContext(Context);

  useEffect(() => {
    const popup = document.querySelector(".popup");
    const overlay = document.querySelector(".overlay-popup");

    if (popup && overlay) {
      popup.classList.remove("hidden");
      overlay.classList.remove("hidden");
    }
  }, []);

  return (
    <>
      <div className="popup hidden">
        <div className="popup-content" ref={ref}>
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/bottom_image.jpg"}
            alt="Image"
            className="popup-image"
          />
          <div className="popup-text">
            <img
              src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/flowlogo1.png"}
              alt="Logo"
              className="popup-logo"
            />
            <p style={{ fontSize: "24px", fontWeight: "400" }}>
              You have {5 - userViews} free article views remaining.
              <br /> Subscribe or share now!
            </p>

            <div style={{ display: "flex" }}>
              <button className="button-pop3">Subscribe!</button>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay-popup"></div>
    </>
  );
});

export default SubscribeOrSharePopup;
