import React, { useState, useEffect } from 'react';
import './signUpPopup.css';
import Footer from '../footer/Footer';

const SignUpPopup = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [initialScroll, setInitialScroll] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 600 && !popupVisible) { // Change this value to your desired scroll position
        setPopupVisible(true);
        setInitialScroll(window.scrollY);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [popupVisible]);

  useEffect(() => {
    if (popupVisible) {
      const preventScroll = (e) => {
        if (window.scrollY > initialScroll) {
          window.scrollTo(0, initialScroll);
        }
      };

      window.addEventListener('scroll', preventScroll);

      return () => {
        window.removeEventListener('scroll', preventScroll);
      };
    }
  }, [popupVisible, initialScroll]);

  const signUp = () => {
    window.location.href = "/register"; // Redirect to the signup page, change URL accordingly
  };

  return (
    <>
      <div className="container-SignUpPopUp">
        {/* Your page content goes here */}
      </div>
      {popupVisible && (
        <>
          <div className="popup-overlay-SignUpPopUp"></div>
          <div className="popup-SignUpPopUp">
            <div className="popup-content-SignUpPopUp">
              <div className='border-content-SignUpPopUp'>
                <p style={{fontSize:"36px", fontWeight:"400"}}>Continue reading this article for free!</p>
                <p style={{fontSize:"24px", fontWeight:"400"}}>Sign up to get access to 5 free articles!</p>
                <button onClick={signUp}>Sign Me Up!</button>
                <p>Already have an account? <a href="/login">SIGN IN</a></p>
              </div>
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SignUpPopup;
