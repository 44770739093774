import { CommentSection } from "react-comments-section";
import "react-comments-section/dist/index.css";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";

export default function Comment({ user, post }) {
  const PF = process.env.REACT_APP_BACKEND_URL + "/api/images";

  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const defaultCmt = {
    avatarUrl: "",
    replies: [],
    comId: "",
    text: "",
    userProfile: "",
    userId: "",
    fullName: "",
    postId: "",
  };
  const [cmts, setCmts] = useState([defaultCmt]);
  useEffect(() => {
    const getCmts = async () => {
      try {
        const res = await axios.get("/comments/" + path);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        if (res.status === 200) {
          setCmts(
            res.data.map((item) => {
              return {
                ...item,
                avatarUrl: item.avatarUrl.startsWith("http")
                  ? item.avatarUrl
                  : PF + item.avatarUrl,
              };
            })
          );
        }
      } catch (err) {
        console.error("Error fetching comments" + err);
        toast.error("Error Fetching Comments!");
      }
    };
    getCmts();
  }, []);
  // console.log("cmt", cmts);
  const handleSubmit = async (data) => {
    try {
      await axios.post(`/comments/`, {
        avatarUrl: data.avatarUrl.replace(PF, ""),
        replies: data.replies,
        comId: data.comId,
        text: data.text,
        userProfile: data.userProfile,
        userId: data.userId,
        fullName: data.fullName,
        postId: path,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error Submitting Comment!");
    }
  };
  const handleDelete = async (data) => {
    if (data.repliedToCommentId == null) {
      try {
        await axios.delete(`/comments/${data.comIdToDelete}`);
      } catch (err) {
        console.log(err);
        toast.error("Error Deleting Comment!");
      }
    } else {
    }
  };
  const handleReply = async (data) => {
    try {
      const res = await axios.get(
        `/comments/?cmtId=${data.repliedToCommentId}`
      );
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      let orgComment = res.data;
      const replyCmt = {
        avatarUrl: data.avatarUrl,
        replies: data.replies,
        comId: data.comId,
        text: data.text,
        userProfile: user.userProfile,
        userId: data.userId,
        fullName: data.fullName,
        postId: path,
      };
      // console.log("replyCmt",replyCmt);
      // console.log(typeof(orgComment.replies), orgComment.replies.length);
      orgComment.replies[orgComment.replies.length] = replyCmt;
      // console.log(typeof(orgComment.replies), orgComment.replies);
      await axios.put(`/comments/${orgComment.comId}`, {
        avatarUrl: orgComment.avatarUrl,
        replies: orgComment.replies,
        comId: orgComment.comId,
        text: orgComment.text,
        userProfile: user.profilePic,
        userId: orgComment.userId,
        fullName: orgComment.fullName,
        postId: path,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error Submitting Reply!");
    }
  };
  const handleEdit = async (data) => {
    try {
      await axios.put(`/comments/${data.comId}`, {
        avatarUrl: data.avatarUrl.replace(PF, ""),
        replies: data.replies,
        comId: data.comId,
        text: data.text,
        userProfile: user.profilePic,
        userId: data.userId,
        fullName: data.fullName,
        postId: path,
      });
    } catch (err) {
      console.log(err);
      toast.error("Error Editing Comment!");
    }
  };
  return (
    <CommentSection
      currentUser={{
        currentUserId: user._id,
        currentUserImg: `https://ui-avatars.com/api/name=${user.user_name}&background=random`,
        currentUserProfile: window.location.origin + "/profile/" + user.userId,
        currentUserFullName: user.user_name,
      }}
      // currentData={(data) => console.log("currentData", data)}
      commentData={cmts.length > 0 && cmts[0].comId !== "" ? cmts : null}
      onSubmitAction={handleSubmit}
      onDeleteAction={handleDelete}
      onReplyAction={handleReply}
      onEditAction={handleEdit}
    />
  );
}
