import { useEffect, useState } from "react";
import Posts from "../../components/posts/Posts";
import "./search.css";
import axios from "axios";
import { useLocation } from "react-router";
import React from "react";
import { toast } from "react-toastify";

export default function Search() {
  const [posts, setPosts] = useState([]);
  const [cats, setCats] = useState([]);
  const searchQuery = new URLSearchParams(useLocation().search).get("q");
  const [url, setUrl] = useState(searchQuery || "");
  //   const [result, setResult] = useState("");

  const fetchPosts = async () => {
    try {
      // alert(url);
      //console.log(url)
      const res = await axios.get("/posts/?search=" + url);
      if (res.status !== 200) {
        toast.info(res.data.msg);
      }
      setPosts(res.data);
    } catch (err) {
      console.error("Error fetching posts! " + err);
      toast.error("Error Fetching Posts!");
    }
    //   setResult('Result:');
  };

  useEffect(() => {
    fetchPosts();
    const getCats = async () => {
      try {
        const res = await axios.get("/categories");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setCats(res.data);
      } catch (err) {
        console.error("Error fetching genres! " + err);
        toast.error("Error Fetching Genres!");
      }
    };
    getCats();
  }, []);

  useEffect(() => {
    fetchPosts();
  }, [url]);

  return (
    <>
      <div className="search">
        <div className="sidebar-search">
          <div className="sidebarSearch-usersearch">
            <form className="searchForm1">
              <input
                type="text"
                placeholder="Search Our Library"
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value.toLowerCase());
                }}
              />
              <button className="searchSubmit1" type="submit">
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </form>
          </div>
          <br></br>
          <h2 style={{ marginLeft: "10%" }}>Results:</h2>
          <Posts posts={posts} />
        </div>
      </div>
    </>
  );
}
