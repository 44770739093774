import "./followers.css";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../context/Context";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

export default function Followers() {
  const PF = process.env.REACT_APP_BACKEND_URL + "/api/images";

  const { user, dispatch } = useContext(Context);
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/users/");
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setUsers(res.data);
      } catch (err) {
        console.error("Error Fetching Users! " + err);
        toast.error("Error Fetching Users!");
      }
    };
    fetchUsers();
  }, []);
  let user_followers = [];
  for (var i = 0; i < user.followers.length; i++) {
    for (var j = 0; j < users.length; j++) {
      if (users[j].userId === user.followers[i]) {
        user_followers.push(users[j]);
      }
    }
  }
  //   console.log("user_followers", user_followers)
  return (
    <div className="Followers">
      <div className="FollowersWrapper">
        <br></br>
        <br></br>
        <h2>Your Followers List:</h2>
        <br></br>
        <br></br>
        {user_followers.length > 0 && (
          <ul className="sidebarList-userHome">
            {user_followers.map((item) => (
              //   <Link to={`/?profile=${item._id}`} className="link">
              <li className="sidebarListItem-follower">
                <div className="left-item">
                  <img src={PF + item.profilePic}></img>
                </div>
                <div className="right-item">
                  <p>Username:{item.username}</p>
                  <p>Email:{item.email}</p>
                </div>
                <div className="right-item2"></div>
              </li>
              //   </Link>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
