import "./author.css";
import React from "react";
import SingleAuthor from "../../components/singleAuthor/SingleAuthor";

export default function Author() {

  return (
    <div>
      <SingleAuthor />
    </div>
  );
}
