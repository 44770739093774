import React from "react";
import "./headerWithLogo.css";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";

const HeaderWithLogo = ({}) => {
  return (
    <div className="logoHome">
      <Link to="/">
        <img
          src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/FLOW_logo.png"}
          alt="Logo"
        />
      </Link>
      <Header />
    </div>
  );
};

export default HeaderWithLogo;
