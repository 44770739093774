import "./loginHeader.css";
import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Context } from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleXmark, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Header() {
  const { user, dispatch } = useContext(Context);
  const [searchOpen, setSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isMenuOpen]);

  return (
    <div style={{ backgroundColor: "#F7F8FB" }}>
      <div className="header-container">
        <div className="header-right-Home">
          <Link to="/subscribe">
            <button className="subscribe-btn-Home buttonHome">SUBSCRIBE</button>
          </Link>
          <div className="separator-home"></div>
          <div className="search-icon" onClick={() => setSearchOpen(true)}>
            <div className="mr-4 inline-block cursor-pointer p-2 text-xl text-dark transition hover:text-primary md:mr-0">
              <FontAwesomeIcon icon={faSearch} size="2x" />
            </div>
          </div>
          <div className="hamburger-icon">
            <input id="menu__toggle"
              type="checkbox"
              checked={isMenuOpen}
              onChange={() => setIsMenuOpen(!isMenuOpen)} />
            <label className="menu__btn" htmlFor="menu__toggle">
              <FontAwesomeIcon icon={faBars} size="2x" style={{ width: "50px", height: "50px", cursor: "pointer" }} />
            </label>
            <div className="menu__overlay"></div>
            <ul className="menu__box">
              <div className="hamburger-margin">
                <li className="menu__item"><Link to="/">Home</Link></li>
                <li className="menu__item"><Link to="/write">Writing Page</Link></li>
                <li className="menu__item"><Link to="/articles">Read Our Articles</Link></li>
                <li className="menu__item"><Link to="/authors">Meet Our Writers</Link></li>
                <li className="menu__item"><Link to="/categories">Choose Your Genre</Link></li>
                <li className="menu__item"><Link to="/profile">Manage Your Account</Link></li>
              </div>
            </ul>
            <div className="social-media-icons">
            </div>
            <label htmlFor="menu__toggle" className="close__btn">
              <FontAwesomeIcon icon={faCircleXmark} size="2x" />
            </label>
          </div>
        </div>
        {searchOpen && <SearchBar onClose={() => setSearchOpen(false)} />}
      </div>
    </div>
  );
}

function SearchBar({ onClose }) {
  return (
    <div className="search-overlay">
      <div className="search-bar-container">
        <form className="searchForm1-search">
          <div className="input-container-search">
            <input
              type="text"
              placeholder="Entering..."
              style={{
                width: "679px",
                height: "52px",
                borderRadius: "10px",
                border: "0.5px solid lightgray",
                paddingRight: "40px",
                position: "relative", // changed from absolute to relative
              }}
              onChange={(e) => {
                // handle search input
              }}
            />
            <button className="searchSubmit1" type="submit" style={{
              position: "absolute",
              right: "10px",
              top: "10px",
              background: "none",
              border: "none",
              cursor: "pointer",
              fontSize: "20px",
              color: "black"
            }}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
          <button className="close-search" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </form>
      </div>
    </div>
  );
}
