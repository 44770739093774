import "./post.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { decodeHtml } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from "react-toastify";

export default function Post({ post }) {
  const [author, setAuthor] = useState({ profilePic: "", avatar_urls: [] });

  useEffect(() => {
    const getAuthor = async () => {
      try {
        const res = await axios.get("/users/" + post.authorId);

        if (res.status !== 200) {
          toast.info(res.data.message);
        }

        // if (res.data.avatar_urls["96"] !== undefined) {
        //   res.data.profilePic = res.data.avatar_urls["96"];
        // }

        setAuthor(res.data);
      } catch (err) {
        console.error("Error fetching author" + err);
        toast.error("Error Fetching Writer!");
      }
    };

    getAuthor();
  }, [post.authorId]); // authorId in the dependency array, ensuring authors refresh when new page posts come in.

  return (
    <div className="post">
      <Link to={`/${post.post_slug}`} className="link">
        {post.photo && (
          <img
            className="postImg"
            src={
              post.photo.startsWith("http")
                ? post.photo
                : process.env.REACT_APP_AWS_S3_IMAGE_URL + post.photo
            }
            alt=""
          />
        )}
      </Link>
      <div className="postInfo">
        <div className="postCats">
          {post.categories.map((c) => (
            <span key={c._id} className="postCat">
              {decodeHtml(c.cat_name)}
            </span>
          ))}
        </div>
        <Link to={`/${post.post_slug}`} className="link">
          <div className="postTitleContainer">
            <span className="postTitle">{decodeHtml(post.title)}</span>
          </div>
        </Link>
        <hr />
        <div className="postDetails">
          <Link to={`/author/${author.userId}`} className="link">
            <img
              className="authorImg"
              src={
                author && author.avatar_urls["48"]
                  ? process.env.REACT_APP_AWS_S3_IMAGE_URL + author.avatar_urls["48"]
                  : author.profilePic + "&size=48"
              }
              alt={author.user_name + " Img"}
            />
          </Link>
          <Link to={`/author/${author.userId}`} className="link">
            <span className="authorName-Post">
              {decodeHtml(author.user_name)}
            </span>
          </Link>
          <span className="postDate">
            {new Date(post.createdAt).toDateString()}
          </span>
        </div>
      </div>
      <p
        style={{ marginBottom: "15px" }}
        className="postDesc"
        dangerouslySetInnerHTML={{ __html: post.shortDesc }}
      ></p>
      <Link to={`/${post.post_slug}`} className="readMoreButton">
        Read More{" "}
        <FontAwesomeIcon icon={faAngleRight} style={{ marginLeft: "20px" }} />
      </Link>
    </div>
  );
}
