import axios from "axios";
import "./forgotPassword.css";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "../../context/Context";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

export default function ForgotPassword() {
  const otpRef = useRef();
  const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { dispatch } = useContext(Context);
  const [otp, setOtp] = useState(false);
  const [verify, setVerify] = useState(false);

  const handleChange = async (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
  };

  const sendOtp = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("/auth/forgotpassword", {
        email,
      });

      if (res.status !== 200) {
        toast.info(res.data);
      }

      setOtp(true);

      toast.success("OTP sent successfully! Please check your email");
    } catch (err) {
      console.log(err)
      toast.error("Error sending OTP!");
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post("/auth/verifyotp", {
        email,
        otp: otpRef.current.value,
      });

      if (res.status !== 200) {
        toast.info(res.data.msg);
      }

      toast.success("Verify OTP successfully!");
      setVerify(true);
    } catch (err) {
      console.log(err);
      toast.error("Couldn't verify OTP!");
    }
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.put("/auth/resetpassword", {
        email,
        oldPassword: oldPasswordRef.current.value,
        newPassword: newPasswordRef.current.value,
      });

      if (res.status !== 200) {
        toast.info(res.data.msg);
      }

      toast.success("Password has changed successfully!");
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data });
      navigate("/");
    } catch (err) {
      console.log(err);
      dispatch({ type: "LOGIN_FAILURE" });
      toast.error("Error Changing Password!");
    }
  };

  return (
    <div className="page-container">
      <div className="left-container">
        <Link to="/">
          <img
            src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/logo1.png"}
            alt="For Love of Writers Logo"
            className="logo-login"
          />
        </Link>
        <img
          src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/bottom_image.jpg"}
          alt="Your Image"
          className="picture"
        />
      </div>
      <div className="right-container-login">
        {verify ? (
          <form className="form-login">
            <h1
              style={{
                marginRight: "34px",
                fontSize: "36px",
                fontWeight: 700,
                paddingBottom: "20px",
              }}
            >
              Reset Your Password !
            </h1>
            <div className="input-container-login">
              <label htmlFor="old-password">Old Password</label>
              <input
                type="text"
                name="old-password"
                id="old-password"
                ref={oldPasswordRef}
                placeholder="Enter your Old Password"
                required
                className="input-login"
              />

              <label htmlFor="new-password">New Password</label>
              <input
                type="text"
                name="new-password"
                id="new-password"
                ref={newPasswordRef}
                placeholder="Enter your new Password"
                required
                className="input-login"
              />
              <div style={{ justifyContent: "center", paddingTop: "20px" }}>
                <button className="login-btn" onClick={resetPassword}>
                  Reset Password
                </button>
              </div>
            </div>
          </form>
        ) : (
          <form className="form-login">
            <h1
              style={{
                marginRight: "23px",
                fontSize: "36px",
                fontWeight: 700,
                paddingBottom: "20px",
              }}
            >
              Forgot Your Password !
            </h1>
            <div className="input-container-login">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleChange}
                placeholder="Email Address"
                required
                className="input-login"
              />
              <div style={{ justifyContent: "center", paddingTop: "20px" }}>
                {otp ? (
                  <>
                    <input
                      name="otp"
                      id="otp"
                      placeholder="Enter Otp Here"
                      required
                      ref={otpRef}
                      className="input-login"
                    />
                    <div className="forgot-button-container">
                      <button className="forgot-btn" onClick={sendOtp}>
                        Resend OTP
                      </button>
                      <button className="forgot-btn" onClick={verifyOtp}>
                        Verify OTP
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <button className="login-btn" onClick={sendOtp}>
                      Send OTP
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}
