import "./followings.css";
import { useContext, useState, useEffect } from "react";
import { Context } from "../../context/Context";
import axios from "axios";
import React from "react";
import { toast } from "react-toastify";

export default function Followings() {
  const PF = process.env.REACT_APP_BACKEND_URL + "/api/images";

  const { user, dispatch } = useContext(Context);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await axios.get("/users/followings/" + user.userId);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        setUsers(res.data);
      } catch (err) {
        console.error("Error fetching users! " + err);
        toast.error("Error Fetching Users!");
      }
    };
    fetchUsers();
  }, []);

  const handleUnfollow = async (i) => {
    try {
      const res = await axios.put(`/users/${user._id}/unfollow`, {
        userId: i,
      });
      if (res.status !== 200) {
          toast.info(res.data.msg);
        }
      setUsers(users.filter((item) => item.userId !== i));
      dispatch({ type: "UPDATE_SUCCESS", payload: res.data });
    } catch (e) {
      dispatch({ type: "UPDATE_FAILURE" });
    }
  };

  return (
    <div className="Followings">
      <div className="FollowingsWrapper">
        <br></br>
        <br></br>
        <h2>Your Followings List:</h2>
        <br></br>
        <br></br>
        {users.length > 0 && (
          <ul className="sidebarList-userHome">
            {users.map((item) => (
              //   <Link to={`/?profile=${item._id}`} className="link">
              <li className="sidebarListItem-following">
                <div className="left-item">
                  <img src={item.profilePic}></img>
                </div>
                <div className="right-item">
                  <p>Username:{item.user_name}</p>
                  <p>Email:{item.email}</p>
                </div>
                <div className="right-item2">
                  <button
                    className="unfollowSubmit"
                    onClick={() => handleUnfollow(item.userId)}
                  >
                    Unfollow
                  </button>
                </div>
              </li>
              //   </Link>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
