import React from "react";
import "./dialogBox.css";

const DialogBox = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay">
      <div className="dialog">
        <div className="dialog-header">
          <h2
            style={{ fontSize: "36px", fontWeight: "700", paddingTop: "20px" }}
          >
            {title}
          </h2>
          <button className="close-btn" onClick={onClose}>
            ✖
          </button>
        </div>
        <hr className="dialog-separator" /> {/* Separator Line */}
        <div className="dialog-body">
          <div className="dialog-text">
            <h2
              style={{
                fontSize: "28px",
                fontWeight: "700",
                paddingTop: "50px",
                paddingBottom: "20px",
              }}
            >
              The FLOW Community
            </h2>
            {children}
          </div>
          <div className="dialog-image">
            <img
              src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/dialog_image.jpg"}
              alt="Dialog Image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
