export function decodeHtml(html) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function createSlug(str) {
  let slug = str.toLowerCase();

  slug = slug.replace(/[^\w\s-]/g, '');

  slug = slug.replace(/[\s_]+/g, '-');

  slug = slug.replace(/^-+|-+$/g, '');

  return slug;
}