import React, { useState, useContext, useEffect } from "react";
import "./subscribe.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../context/Context";
import axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleXmark, faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Subscribe() {
  const { user, token, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const [searchOpen, setSearchOpen] = useState(false);
  const [subscription, setSubscription] = useState({});

  const handleLogout = () => {
    localStorage.removeItem('token');
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const getSubscription = async () => {
      if (!user || !user.userId) return;
      try {
        const res = await axios.get(`/users/${user.userId}`);
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const planType = res.data.subscription.plan;
        const subscriptionId = res.data.subscription.subscriptionId;
        setSubscription({ plan: planType, subscriptionId: subscriptionId });
      } catch (err) {
        console.error("Error getting Subscription! " + err);
        toast.error("Error Fetching Subscription Details!");
      }
    };
     if (user) {
       getSubscription();
     }
  }, [user]);


  const handleSubscribe = async (plan) => {
    if (!user) {
      toast.info("You Need To Login First!");
      navigate("/login");
    } else {
      try {
        const userId = user ? user._id : null;
        const currentPlan = subscription.plan;
        if (currentPlan !== "plan") {
          toast.info("Manage your active subscription in your profile!");
        }
        const res = await axios.post("/stripe/create-checkout-session", {
          plan,
          userId,
        }, {headers: {'Authorization':`Bearer ${token}`}});
        if (res.status !== 200) {
          toast.info(res.data.msg);
        }
        const session = await res.data;
        window.location.href = session.url;
      } catch (error) {
        toast.error("Error Occured During Checkout!");
        console.error("Error creating checkout session: ", error);
      }
    }
  };

  return (
    <>
      <div className="header-subscribe">
        <div className="logo-subscribe">
          <Link to="/">
            <img
              src={process.env.REACT_APP_AWS_S3_IMAGE_FRONTEND_URL + "/FLOW_logo.png"}
              alt="Logo"
              style={{ height: "233px", width: "233px" }}
            />
          </Link>
        </div>
        <div className="header-right">
          {user ? (
            <div className="subscribe-btn" onClick={handleLogout}>
              Log Out
            </div>
          ) : (
            <div className="subscribe-btn">
              <Link
                to="/login"
                style={{ textDecoration: "none", color: "black" }}
              >
                Sign In
              </Link>
            </div>
          )}
          <div className="separator-home"></div>
        <div className="search-icon" onClick={() => setSearchOpen(true)}>
          <div className="mr-4 inline-block cursor-pointer p-2 text-xl text-dark transition hover:text-primary md:mr-0">
            <FontAwesomeIcon icon={faSearch} size="2x" />
          </div>
        </div>
        <div className="hamburger-icon">
          <input id="menu__toggle" type="checkbox" />
          <label className="menu__btn" htmlFor="menu__toggle">
            <FontAwesomeIcon icon={faBars} size="2x" style={{ width: "50px", height: "50px", cursor: "pointer" }} />
          </label>
          <div className="menu__overlay"></div>
          <ul className="menu__box">
            <div style={{ marginTop: "150px" }}>
              <li className="menu__item"><Link to="/">Home</Link></li>
              <li className="menu__item"><Link to="/articles">Read Our Articles</Link></li>
              <li className="menu__item"><Link to="/authors">Meet Our Writers</Link></li>
              <li className="menu__item"><Link to="/categories">Choose Your Genre</Link></li>
              <li className="menu__item"><Link to="/profile">Manage Your Account</Link></li>
            </div>
          </ul>
          <div className="social-media-icons">
          </div>
          <label htmlFor="menu__toggle" className="close__btn">
            <FontAwesomeIcon icon={faCircleXmark} size="2x" />
          </label>
        </div>
        </div>
        {searchOpen && <SearchBar onClose={() => setSearchOpen(false)} />}
      </div>

      <div className="mobile-menu hidden">
        <div className="mobile-menu-content">
          <ul className="menu-items">
            <li className="menu-item">
              <a href="/articles">Read Our Articles</a>
            </li>
            <li className="menu-item">
              <a href="/authors">Meet Our Writers</a>
            </li>
            <li className="menu-item">
              <a href="/categories">Choose Your Genre</a>
            </li>
            <li className="menu-item">
              <a href="/profile">Manage Your Account</a>
            </li>
          </ul>
          {/* <div className="social-icons">
            <Social source={social} className="text-3xl space-x-4" />
          </div> */}
          <div className="close-icon-container">
            <i
              className="fa fa-times closeIcon"
              style={{ fontSize: "30px", color: "black" }}
            ></i>
          </div>
        </div>
      </div>

      <div className="center-text">
        <h1 style={{ fontSize: "40px", paddingBottom:"20px" }}>
          Choose the plan that's right for you!
        </h1>
      </div>
      <div className="center-text">
        <p style={{ fontSize: "25px", marginBottom: "5rem" }}>
          Choose the membership subscription that best fits your needs!
        </p>
      </div>

      <section className="card-container-subscribe">
        <div className="card-subscribe">
          

          <div style={{ paddingLeft: "25px" }}>

          <h2
            style={{
              fontSize: "25px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            MONTHLY PLAN
          </h2>
            <p style={{ fontSize: "40px", fontWeight: 700 }}>
              $10
              <span style={{ fontSize: "20px", fontWeight: 400 }}>&nbsp;CAD</span>
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "20px",
              }}
            >
              /Month
            </p>
            <p style={{ fontSize: "20px", fontWeight: 400 }}>$2.50 per week</p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "30px",
              }}
            >
              Billed Monthly
            </p>
          </div>

          <div style={{ display: "flex" }}>
            {/* <a href="/payment"> */}
            <button
              style={{
                height: "63px",
                width: "277px",
                fontSize: "28px",
                fontWeight: "700",
              }}
              onClick={() => handleSubscribe("monthly")}
            >
              Subscribe Now!
            </button>
            {/* </a> */}
          </div>
        </div>

        <div className="card-subscribe">
          
          <div style={{ paddingLeft: "25px", position: "relative" }}>
          <div style={{
                    position: "absolute",
                    top: "-50px",
                    right: "-90px",
                    width: "219px",
                    height: "61px",
                    backgroundColor: "#C9CDD3", // Example color, adjust as needed
                    color: "#000000",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "700",
                    borderRadius: "10px",
                    fontSize:"24px",
                    fontWeight:"700"
                    }}>
                    3 MONTHS FREE  !
                    </div>   
          <h2
            style={{
              fontSize: "25px",
              fontWeight: 700,
              marginBottom: "20px",
            }}
          >
            ANNUAL PLAN
          </h2>
            <p style={{ fontSize: "40px", fontWeight: 700 }}>
              $90
              <span style={{ fontSize: "20px", fontWeight: 400 }}>&nbsp;CAD</span>
            </p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "20px",
              }}
            >
              /Year
            </p>
            <p style={{ fontSize: "20px", fontWeight: 400 }}>$1.75 per week</p>
            <p
              style={{
                fontSize: "20px",
                fontWeight: 400,
                marginBottom: "30px",
              }}
            >
              Billed annually
            </p>
          </div>
          <div style={{ display: "flex" }}>
            {/* <a href="/payment"> */}
            <button
              style={{
                height: "63px",
                width: "277px",
                fontSize: "28px",
                fontWeight: "700",
              }}
              onClick={() => handleSubscribe("annual")}
            >
              Subscribe Now!
            </button>
            {/* </a> */}
          </div>
        </div>
      </section>
    </>
  );
}

function SearchBar({ onClose }) {
  return (
    <div className="search-overlay">
      <div className="search-bar-container">
        <form className="searchForm1-search">
          <div className="input-container-search">
            <input
              type="text"
              placeholder="Entering..."
              style={{
                width: "679px",
                height: "52px",
                borderRadius: "10px",
                border: "0.5px solid lightgray",
                paddingRight: "40px",
                position: "relative", // changed from absolute to relative
              }}
              onChange={(e) => {
                // handle search input
              }}
            />
            <button className="searchSubmit1" type="submit" style={{
                position: "absolute",
                right: "10px",
                top: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "20px",
                color: "black"
              }}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
            
          </div>
          <button className="close-search" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="lg" />
        </button>
        </form>
        
      </div>
    </div>
  );
}
