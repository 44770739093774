const Reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_START":
      return {
        user: null,
        isFetching: true,
        error: false,
        userViews: state.userViews,
        token: state.token,
      };
    case "LOGIN_SUCCESS":
      return {
        user: action.payload,
        isFetching: false,
        error: false,
        userViews: state.userViews,
        token: action.payload.token,
      };
    case "LOGIN_FAILURE":
      return {
        user: null,
        isFetching: false,
        error: true,
        userViews: state.userViews,
        token: null,
      };
    case "UPDATE_START":
      return {
        ...state,
        isFetching: true,
      };
    case "UPDATE_SUCCESS":
      return {
        user: action.payload,
        isFetching: false,
        error: false,
        userViews: state.userViews,
        token: state.token,
      };
    case "UPDATE_FAILURE":
      return {
        user: state.user,
        isFetching: false,
        error: true,
        userViews: state.userViews,
        token: state.token,
      };
    case "INCREMENT_USER_VIEWS":
      const { articleId } = action.payload;
      if (state.uniqueArticleIds) {
        if (!state.uniqueArticleIds.has(articleId)) {
          return {
            ...state,
            userViews: state.userViews + 1,
            uniqueArticleIds: new Set([...state.uniqueArticleIds, articleId]),
          };
        }
      }

      return state;
    case "LOGOUT":
      return {
        user: null,
        isFetching: false,
        error: false,
        userViews: state.userViews,
        token: null,
      };
    default:
      return state;
  }
};

export default Reducer;
